@media screen and (min-width: 1801px)
    #toolflyers
        width: 91%!important
        padding-right: 5px!important
        padding-left: 5px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 22px!important
        height: 65px!important
        padding-top: 15px!important
    .toolShipment
        font-size: 14px
    .effDate
        font-size: 12px
    .stylingTd
        font-size: 12px

@media screen and (min-width: 1600px) and (max-width: 1800px)
    #toolflyers
        width: 91%!important
        padding-right: 5px!important
        padding-left: 5px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 22px!important
        height: 65px!important
        padding-top: 15px!important
    .toolShipment
        font-size: 14px
    .effDate
        font-size: 12px
    .stylingTd
        font-size: 12px

@media screen and (min-width: 1400px) and (max-width: 1599px)
    #toolflyers
        width: 91%!important
        padding-right: 4px!important
        padding-left: 4px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 22px!important
        height: 50px!important
        padding-top: 10px!important
    .toolShipment
        font-size: 13px
    .effDate
        font-size: 13px
    .stylingTd
        font-size: 13px


@media screen and (min-width: 1200px) and (max-width: 1399px)
    #toolflyers
        width: 89%!important
        padding-right: 4px!important
        padding-left: 4px!important 
        margin-left: 12px!important
        font-size: 18px!important
    .mkt_box_title
        font-size: 17px!important
        height: 50px!important
        padding-top: 13px!important

@media screen and (min-width: 1000px) and (max-width: 1199px)
    #toolflyers 
        width: 85%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 13px !important
        height: 34px !important
        padding-top: 7px !important
    .toolShipment
        font-size: 10px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px

@media screen and (min-width: 766px) and (max-width: 999px)
    #toolflyers 
        width: 85%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 14px !important
        height: 36px !important
        padding-top: 6px !important
    .toolShipment
        font-size: 10px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px

@media screen and (min-width: 575px) and (max-width: 765px)
    #toolflyers 
        width: 85%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 15px !important
        height: 35px !important
        padding-top: 8px !important
    .toolShipment
        font-size: 10px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px
@media screen and (min-width: 465px) and (max-width: 574px)
    #toolflyers 
        width: 89%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 18px!important
        height: 50px!important
        padding-top: 11px!important
    .toolShipment
        font-size: 10px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px

@media screen and (min-width: 350px) and (max-width: 464px)
    #toolflyers 
        width: 88%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 14px!important
    .mkt_box_title
        font-size: 18px!important
        height: 50px!important
        padding-top: 11px!important
    .toolShipment
        font-size: 10px
    .effDate
        font-size: 8px
    .stylingTd
        font-size: 8px
@media screen and (min-width: 250px) and (max-width: 349px)
    #toolflyers 
        width: 83%!important
        padding-right: 1px!important
        padding-left: 1px!important 
        margin-left: 13px!important
    .mkt_box_title
        font-size: 12px!important
        height: 35px!important
        padding-top: 8px!important
    .toolShipment
        font-size: 8px
    .effDate
        font-size: 6px
    .stylingTd
        font-size: 6px